function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
const cardFieldsConfig = {
    type: FIELD_TYPES.NESTED,
    fields: {
        isProductCard: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Is product card"
        },
        image: {
            type: FIELD_TYPES.GALLERY,
            label: "Card image"
        },
        backImage: {
            type: FIELD_TYPES.GALLERY,
            label: "Back image"
        },
        cardText: {
            type: FIELD_TYPES.TEXT,
            label: "Card text"
        },
        backgroundColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Background color"
        }
    }
};
export default {
    type: FIELD_TYPES.NESTED,
    label: "Multi Cards Carousel",
    fields: {
        carouselTitle: {
            type: FIELD_TYPES.NESTED,
            label: "Title with arrows",
            fields: _object_spread_props(_object_spread({}, TitleConfig), {
                marginBottom: {
                    type: FIELD_TYPES.TEXT,
                    label: "Margin bottom"
                },
                withArrows: {
                    type: FIELD_TYPES.BOOLEAN,
                    label: "With arrows"
                }
            })
        },
        cards: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Carousel Cards",
            maxLimit: 5,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    firstRowCards: {
                        label: "First row cards",
                        type: FIELD_TYPES.MULTIPLE_NESTED,
                        maxLimit: 4,
                        nestedConfig: cardFieldsConfig
                    },
                    secondRowCards: {
                        label: "Second row cards",
                        type: FIELD_TYPES.MULTIPLE_NESTED,
                        maxLimit: 4,
                        nestedConfig: cardFieldsConfig
                    }
                }
            }
        }
    }
};
